(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('checklistAvailabilityController', checklistAvailabilityController);

    checklistAvailabilityController.$inject = ['$scope', '$state', 'Department', 'BrapChecklist','District','DistrictVillageBlockMapping', 'DepartmentService'];

    function checklistAvailabilityController($scope, $state, Department, BrapChecklist,District,DistrictVillageBlockMapping,DepartmentService) {
        var vm = this;
        vm.checklistAvailability = {};
        vm.departments = [];

        // Function to get department data
        function getDepartments() {
            Department.query().$promise.then(function(data) {
                vm.departments = data;
            }).catch(function(error) {
                console.error('Error fetching departments:', error);
            });
        }

        getDepartments();
        loadAllDistrict();

        vm.updateDepartmentId = function() {
            var selectedDepartment = vm.departments.find(function(department) {
                return department.id === vm.selectedDepartment;
            });
            if (selectedDepartment) {
                vm.checklistAvailability.departmentId = selectedDepartment.id;
            } else {
                vm.checklistAvailability.departmentId = null; // Or handle as needed
            }
        };

        vm.submitForm = function() {
            var brapChecklistDTO = {
                departmentId: vm.checklistAvailability.departmentId,
                riskCategory: vm.checklistAvailability.riskCategory,
                sizeOfFirm: vm.checklistAvailability.sizeOfFirm,
                businessLocation: vm.checklistAvailability.businessLocation,
                investerType: vm.checklistAvailability.investorType
            };

            BrapChecklist.getDocumentUrl(brapChecklistDTO).$promise.then(function(response) {
                // Log the entire response to verify structure
                console.log('Full Response:', response);


                // Extract the document URL from the response object
                var cleanedDocumentUrl = Object.values(response).join('');
                var documentUrl = cleanedDocumentUrl.match(/^[^\[]+/)[0];
                // Log the URL to verify it is correct
                console.log('Document URL to pass:', documentUrl);
              

                // Pass the document URL to the state
                $state.go('searchResults', { responseData: documentUrl });
            }).catch(function(error) {
                console.error('Error fetching document URL:', error);
            });
        };

        vm.getVillages = function (districtName) {
            vm.showEstateConfirmation =true;
            vm.isIndustrialEstate = "no";
            vm.showIndustrialEstates = false;
            DistrictVillageBlockMapping.query({id: '1',districtName: districtName},function(result) {
                vm.villages = result;
                vm.searchQuery = null;
                vm.checklistAvailability.village = null;
                vm.checklistAvailability.blockCategory = null;
            });
        };

        vm.setBlockCategory = function (villageName, villageList) {
            vm.showEstateConfirmation = true;
            vm.villageNames = [];
            angular.forEach(villageList, function (value, key) {
                vm.villageNames.push(value.village);
            });
            if (vm.villageNames.indexOf(villageName) !== -1) {
                vm.invalidVillage = false;
                vm.checklistAvailability.blockCategory = villageList[vm.villageNames.indexOf(villageName)].category;
            } else {
                vm.invalidVillage = true;
            }
        };



        vm.districts = [];
        function loadAllDistrict(){
            District.query(function(result) {
                vm.districtstemp = result;
                angular.forEach(vm.districtstemp, function(value, key){
                    if((value.districtname!=='Narnaul' && value.districtname!== 'Chandigarh HQ')){
                        vm.districts.push(value);
                    }
                });
                vm.searchQuery = null;
            });
        }

        vm.getServices = function (id) {
                DepartmentService.query({id: '1',departmentid: id},function(result) {
                vm.services = result;

            });
        }



    }
})();
