(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StartupPoliciesLoginController', StartupPoliciesLoginController);

    StartupPoliciesLoginController.$inject = ['$stateParams','$scope', '$state','IncentiveForm', 'Principal', 'LoginService', '$timeout', '$location'];

    function StartupPoliciesLoginController($stateParams,$scope, $state, IncentiveForm, Principal, LoginService, $timeout , $location) {

        var vm = this;
        vm.url = null;
        vm.isAuthenticated = Principal.isAuthenticated;
        IncentiveForm.query(function(result){
            vm.userdetails=result;

        });
      //   vm.schemeName="seed-fund-startup-tranche2";
      //   vm.email=$stateParams.email;
      //   vm.name=$stateParams.name;
      //  //  vm.startupUrl = "https://startupharyana.gov.in/invest-haryana/apply-scheme";
      //  // var searchObject = $location.search();
      //  // alert( searchObject.schemeName );
      //   // Function to add hidden fields to the form
      //   function addHiddenField(form, name, value) {
      //       var input = document.createElement('input');
      //       input.type = 'hidden';
      //       input.name = name;
      //       input.value = value;
      //       form.appendChild(input);
      //   }
      //
      // //  alert(vm.schemeName);
      //   $timeout(function () {
      //       var submitButton = document.querySelector('#myForm input[type="submit"]');
      //       var form = document.querySelector('#myForm');
      //
      //       if (form && submitButton) {
      //           console.log("Adding hidden fields and triggering click on submit button...");
      //
      //
      //           // Add the parameters as hidden fields
      //           addHiddenField(form, "schemeName", vm.schemeName);
      //           addHiddenField(form, 'email', vm.email);
      //           addHiddenField(form, 'name', vm.name);
      //           // Simulate a click on the submit button
      //           submitButton.click();
      //       } else {
      //           alert("Form or Submit button not found!");
      //       }
      //   }, 100);

        vm.setUrl = function (url) {
            vm.url = $sce.trustAsResourceUrl(url);
        };

        vm.login = function login() {
            LoginService.open();
        };
    }
})();
