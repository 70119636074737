(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('StartupPoliciesController', StartupPoliciesController);

    StartupPoliciesController.$inject = ['$stateParams','$scope', '$state','IncentiveForm', 'Principal', 'LoginService', '$timeout', '$location'];

    function StartupPoliciesController($stateParams,$scope, $state, IncentiveForm, Principal, LoginService, $timeout , $location) {

        var vm = this;
        vm.url = null;
        vm.isAuthenticated = Principal.isAuthenticated;
        IncentiveForm.query(function(result){
            vm.userdetails=result;

        });
        vm.setUrl = function (url) {
            vm.url = $sce.trustAsResourceUrl(url);
        };

    }
})();
