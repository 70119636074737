(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('hepc-startupharyana', {
            parent: 'app',
            url: '/hepc-startupharyana/{schemeName}/{email}/{name}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/hepc-startupharyana/hepc-startupharyana.html',
                    controller: 'HepcStartupHaryanaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: [ function() {
                    return true;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });        ;

    }
})();
