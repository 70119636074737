(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HepcStartupHaryanaController', HepcStartupHaryanaController);

    HepcStartupHaryanaController.$inject = ['$scope', '$rootScope', 'LoginService', '$state', '$stateParams', '$timeout'];

    function HepcStartupHaryanaController ($scope, $rootScope, LoginService, $state, $stateParams, $timeout) {
        var vm = this;

        vm.account = null;
        vm.login = LoginService.open;


          vm.schemeName= $stateParams.schemeName;
          vm.email=$stateParams.email;
          vm.name=$stateParams.name;
         //  vm.startupUrl = "https://startupharyana.gov.in/invest-haryana/apply-scheme";
         // var searchObject = $location.search();
         // alert( searchObject.schemeName );
          // Function to add hidden fields to the form
          function addHiddenField(form, name, value) {
              var input = document.createElement('input');
              input.type = 'hidden';
              input.name = name;
              input.value = value;
              form.appendChild(input);
          }

          vm.urlStartup = "https://startupharyana.gov.in/invest-haryana/sso-login" + "?schemeName=" + vm.schemeName;

        //  alert(vm.schemeName);
          $timeout(function () {
              var submitButton = document.querySelector('#myForm input[type="submit"]');
              var form = document.querySelector('#myForm');

              if (form && submitButton) {
                  console.log("Adding hidden fields and triggering click on submit button...");


                  // Add the parameters as hidden fields
                  addHiddenField(form, "schemeName", vm.schemeName);
                  addHiddenField(form, 'email', vm.email);
                  addHiddenField(form, 'name', vm.name);
                  // Simulate a click on the submit button
                  submitButton.click();
              } else {
                  alert("Form or Submit button not found!");
              }
          }, 100);


        vm.login = function login() {
            LoginService.open();
        };

        vm.reload = function reload() {
          window.location.reload();
        };

        vm.setUrl = function (url) {
            vm.url = $sce.trustAsResourceUrl(url);
        };
    }
})();
