(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('startupPolicies', {
            parent: 'app',
            url: '/startupPolicies',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/startupPolicies/startupPolicies.html',
                    controller: 'StartupPoliciesController',
                    controllerAs: 'vm'
                }
            }
        })
    .state('withoutlogin', {
            parent: 'startupPolicies',
            url: '/withoutlogin',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/startupPolicies/startupPolicies-login.html',
                    controller: 'StartupPoliciesLoginController',
                    controllerAs: 'vm'
                }
            }
        })
    }
})();
