(function() {
    'use strict';

    angular
        .module('investhryApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('export', {
            parent: 'app',
            url: '/export',
            data: {
                authorities: [],
                pageTitle: 'global.menu.export'
            },
            views: {
                'content@': {
                    templateUrl: 'app/export/export.html',
                    controller: 'ExportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('export');
                    return $translate.refresh();
                }]
            }
        }).state('exportdistrict', {
            parent: 'export',
            url: '/exportdistrict',
            data: {
                authorities: [],
                pageTitle: 'global.menu.export'
            },
            views: {
                'content@': {
                    templateUrl: 'app/export/exportdistrict.html',
                    controller: 'ExportDistrictController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('export');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
