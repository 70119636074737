(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('ExportDistrictController', ExportDistrictController);

    ExportDistrictController.$inject = ['$scope', 'ExportService'];

    function ExportDistrictController($scope, ExportService) {
        var vm = this;

    }
})();
