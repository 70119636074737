(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('EodbNotificationsController', EodbNotificationsController);

    EodbNotificationsController.$inject = ['$scope', 'EodbNotificationsService'];

    function EodbNotificationsController($scope, EodbNotificationsService) {
        var vm = this;

    }
})();
