(function() {
    'use strict';

    angular
        .module('investhryApp', [
            'ngStorage',
            'textAngular',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'chart.js',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'btorfs.multiselect',
            'infinite-scroll',
            'angular.filter',
            'checklist-model',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$translate'];

    function run(stateHandler, translationHandler, $translate) {
        // Initialize state and translation handlers
        stateHandler.initialize();
        translationHandler.initialize();

        // Retrieve language preference from localStorage
        var preferredLanguage = localStorage.getItem('preferredLanguage') || 'en'; // Default to 'en' if not set
        $translate.use(preferredLanguage); // Set the preferred language

        // Google Analytics initialization (unchanged)
        (function(b,o,i,l,e,r){b.GoogleAnalyticsObject=l;b[l]||(b[l]=
            function(){(b[l].q=b[l].q||[]).push(arguments)});b[l].l=+new Date;
            e=o.createElement(i);r=o.getElementsByTagName(i)[0];
            e.src='//www.google-analytics.com/analytics.js';
            r.parentNode.insertBefore(e,r)}(window,document,'script','ga'));
        ga('create','UA-138860801-1','auto');
    }
})();
