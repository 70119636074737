(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$location', '$scope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService','Minutesofmeeting','downloadserviceformattachment', 'Visitor_count','translationHandler'];

    function NavbarController ($location, $scope, $state, Auth, Principal, ProfileService, LoginService,Minutesofmeeting,downloadserviceformattachment, Visitor_count,translationHandler) {
        var vm = this;
        vm.more = more;
        $scope.selectedLanguage = localStorage.getItem('language') || 'en'; // Load saved language

        setCookie();

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        $scope.changeLanguage = function(langKey) {
            translationHandler.changeLanguage(langKey); // Change language globally
        };

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.viewproject = viewproject;

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };
        getcurrentuser();

        function getcurrentuser() {
            Principal.identity().then(function (account) {
                if (account !== null) {
                    vm.account = account;
                    vm.username = account.login;
                    if ((account.firstName === '' || account.firstName === undefined || account.firstName === null) && (account.lastName === '' || account.lastName === undefined || account.lastName === null)) {
                        vm.currentuser = null;
                        return;
                    }
                    vm.currentuser = account.firstName + ' ' + account.lastName;
                }
            });
        }

        // idleTime();
        // function idleTime() {
        //
        //     var idleTime = 0;
        //     $(document).ready(function () {
        //         //Increment the idle time counter every minute.
        //         var idleInterval = setInterval(timerIncrement,60000); // 1 minute
        //
        //         //Zero the idle timer on mouse movement.
        //         $(this).mousemove(function (e) {
        //             idleTime = 0;
        //         });
        //         $(this).keypress(function (e) {
        //             idleTime = 0;
        //         });
        //     });
        //
        //     function timerIncrement() {
        //         idleTime = idleTime + 1;
        //         if (idleTime > 15) {// 30 minutes
        //         logout();
        //         }
        //     }
        // }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function viewproject() {
            getAccount();

            function getAccount() {
                Principal.identity().then(function (account) {
                    vm.account = account;
//                        console.log(vm.account);
                    if ((vm.account.id == 3) || (vm.account.id == 'fd782532-e971-4eab-9420-ce2da2538b2f')) {
                        $state.go('viewProjectAdmin');
                    } else {
                        $state.go('viewprojectdetail', {status: 'Pending'});
                    }
                    vm.isAuthenticated = Principal.isAuthenticated;
                });
            }
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        loadAllMinutesofMeeting();

        function loadAllMinutesofMeeting() {
            Minutesofmeeting.query(function (result) {
                vm.minutesofmeetings = result;
                vm.searchQuery = null;
            });
        }

        vm.downloadFile = downloadFile;

        function downloadFile(serverfilename, downloadfilename) {
            downloadserviceformattachment.download(serverfilename, downloadfilename)
                .then(function (success) {
                    console.log('success : ' + success);
                }, function (error) {
                    console.log('error : ' + error);
                });
        }

        function setCookie() {
            // $.removeCookie('count');
            if ($.cookie('count') === undefined) {
                $.cookie('count', 0);
            } else {
                $.cookie('count', 1);
            }
            setCounter();
        }

        function setCounter() {
            if ($.cookie('count') === '0') {
                Visitor_count.get({id: '1628837e-b177-43d4-8eac-e8e3798dd777'}, function (result) {
                    vm.visitor_count = result;
                    vm.searchQuery = null;
                    vm.visitor_count.count = ++vm.visitor_count.count;
                    Visitor_count.update(vm.visitor_count);
                });
            }
        }

        vm.meeting_view = function (type) {
            console.log(type);
            if (type === 'HEPB') {
                vm.show = true;
                vm.show1 = false;
            } else if (type === 'EEC') {
                vm.show1 = true;
                vm.show = false;
            }
        };
        vm.toggleMenu = function () {
            jQuery('.toggle_menu').toggleClass('mobile-active');
            jQuery('.page_header').first().toggleClass('mobile-active');

        }

        function more(value) {
            if (value === "true") {
                vm.isShowEPP = true
            } else {
                vm.isShowEPP = false
            }

        }
    }
})();
