(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance','Principal','CheckContent'];

    function LoginController($rootScope, $state, $timeout, Auth, $uibModalInstance, Principal, CheckContent) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.roles = [];
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.captchaInput = '';
        vm.captchaError = false;
        vm.captchaCode = '';
        vm.refreshCaptcha = generateCaptcha;

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});

        $timeout(function () {
            angular.element('#username').focus();
            generateCaptcha(); // Generate CAPTCHA on load
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            event.preventDefault();
            if (vm.captchaInput !== vm.captchaCode) {
                vm.captchaError = true;
                vm.refreshCaptcha(); // Refresh CAPTCHA on incorrect input
                return;
            } else {
                vm.captchaError = false;
            }

            var encPassword = encryptPassword(vm.password);
            Auth.login({
                username: vm.username,
                password: encPassword,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }
                getCurrentRole();
                $rootScope.$broadcast('authenticationSuccess');
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register() {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword() {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

        function getCurrentRole(){
            Principal.identity().then(function(account){
                if (account.authorities.indexOf('ROLE_USER') > -1 && (account.authorities.indexOf('ROLE_ADMIN') < 0 || account.authorities.indexOf('ROLE_SUB_ADMIN') < 0)) {
                    $state.go('listproject', {}, { reload: true });
                } else if (account.authorities.indexOf('ROLE_NODAL_OFFICER') > -1) {
                    $state.go("nodalofficerviewprojectdetail", {status: 'Pending'}, { reload: true });
                } else if (account.authorities.indexOf('ROLE_TOWER_DC') > -1) {
                    $state.go("projects", {}, { reload: true });
                } else {
                    $state.go('home', {}, { reload: true });
                }
            });
        }

        vm.handleApplyNow = function (event) {
            // Close the login popup
            $uibModalInstance.dismiss('cancel');
            // Allow form submission to proceed
        };

        function encryptPassword(plainString) {
            var iv = "a407e444cd6179be5ced97caf0ff11cb";
            var salt = "a826254e90f626b011eb21aca9a79b65";
            var keySize = 128;
            var iterationCount = 10000;
            var passPhrase = "NAimIodPNkqOyHQXmEpoFA==Sdty=jHY";
            var aesUtil = new AesUtil(keySize, iterationCount);
            var encryptedString = aesUtil.encrypt(salt, iv, passPhrase, plainString);
            return encryptedString;
        }

        // Function to generate a random CAPTCHA code with at least one uppercase, one lowercase letter, and one number
        function generateCaptcha() {
            var canvas = document.getElementById('captchaCanvas');
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#000000';
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Helper function to get random uppercase letter, lowercase letter, or digit
            function getRandomUppercaseLetter() {
                return String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
            }

            function getRandomLowercaseLetter() {
                return String.fromCharCode(Math.floor(Math.random() * 26) + 97); // a-z
            }

            function getRandomDigit() {
                return String.fromCharCode(Math.floor(Math.random() * 10) + 48); // 0-9
            }

            // Generate a CAPTCHA code with at least one uppercase, one lowercase, and one digit
            var captchaArray = [];
            captchaArray.push(getRandomUppercaseLetter()); // Add one uppercase letter
            captchaArray.push(getRandomLowercaseLetter()); // Add one lowercase letter
            captchaArray.push(getRandomDigit());           // Add one digit

            // Fill the rest (3 characters) with random letters (upper/lower) or numbers
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (var i = 0; i < 3; i++) {
                var randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
                captchaArray.push(randomChar);
            }

            // Shuffle the array to randomize the order
            for (var i = captchaArray.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = captchaArray[i];
                captchaArray[i] = captchaArray[j];
                captchaArray[j] = temp;
            }

            // Convert array to string
            vm.captchaCode = captchaArray.join('');

            // Add random lines for distortion
            for (var i = 0; i < 10; i++) {
                var opacity = Math.random(); // Random opacity
                ctx.strokeStyle = 'rgba(255, 255, 255, ' + opacity + ')';
                ctx.lineWidth = Math.random() * 2;
                ctx.beginPath();
                ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
                ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
                ctx.stroke();
            }

            // Add background noise (dots)
            for (var i = 0; i < 100; i++) {
                var dotOpacity = Math.random(); // Random opacity for dots
                ctx.fillStyle = 'rgba(255, 255, 255, ' + dotOpacity + ')';
                ctx.beginPath();
                ctx.arc(Math.random() * canvas.width, Math.random() * canvas.height, Math.random() * 2, 0, Math.PI * 2);
                ctx.fill();
            }

            // Draw CAPTCHA text with a distorted effect
            ctx.font = 'bold 30px Arial';
            ctx.fillStyle = '#FFFFFF'; // White text color
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'; // Black shadow with some transparency
            ctx.shadowBlur = 4; // Increase blur size as needed
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.fillText(vm.captchaCode, 25, 40); // Position text in canvas
        }

        vm.close = function close() {
            alert("dd");
            window.location.reload();
        };


    }
})();

